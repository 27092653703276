import React from "react"

import Header from "../components/header" 
import Hero from "../components/hero" 
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
	 <>
	  <Header />
	  <Hero>
	  	<h1>About</h1>
	  </Hero>
	  <Layout>
	  <SEO title="Employee Assistance Help & Resources" keywords={[`gatsby`, `application`, `react`]} />
	  
	  
	  </Layout>
	 </>
)

export default AboutPage